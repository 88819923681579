import { Controller } from "@hotwired/stimulus";
import dragscroll from "dragscroll";
import { DataTable } from "simple-datatables"
require("simple-datatables/dist/style")
import hljs from "highlight.js"
require("highlight.js/styles/github.css")

export default class extends Controller {
	static targets = [];

    initialize() {
        dragscroll.reset();

        hljs.highlightAll();

        let dataTables = Array.from(document.querySelectorAll(".js-data-table"));

        dataTables.map(el => {
            new DataTable(el, {
                paging: false,
                searchable: false
            });
        });

        let dataTableWrappers = Array.from(document.querySelectorAll(".dataTable-wrapper"));

        dataTableWrappers.map(el => {
            el.classList.add("dragscroll");
        });
    }

}
