import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
	static targets = [
        "cookiesDialog"
    ];

    initialize() {
        var hasConsented = Cookies.get('privacy-consent');

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });

        if (hasConsented == undefined) {
            setTimeout(() => {
                this.cookiesDialogTarget.classList.remove("cookies--hidden");
            }, 500);
        } else if (hasConsented == "true") {
            gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
        }
    }

    acceptCookies() {
        Cookies.set('privacy-consent', true, { expires: 365 });
        this.cookiesDialogTarget.classList.add("cookies--hidden");

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    }

    rejectCookies() {
        Cookies.set('privacy-consent', false, { expires: 365 });
        this.cookiesDialogTarget.classList.add("cookies--hidden");
    }

}
