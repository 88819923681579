import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import Cookies from "js-cookie";
import tippy from "tippy.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { errorToast, successToast } from "../helpers/toast_helpers";

export default class extends Controller {
    static values = {
        mobileMenu: String
    }

	static targets = [
        "mobileMenuBackground",
        "mobileMenu",
        "mobileMenuLink",
        "resendVerificationEmailButton",
        "stickyHeader",
        "verificationEmail"
    ];

    initialize() {
        this.isMobileMenuOpen = false;

        clearAllBodyScrollLocks();
        this.setUserSourceAndReferrer();

        tippy('[data-tippy-content]', {
            allowHTML: true,
            animation: "shift-away",
            touch: true
        });
    }

    setUserSourceAndReferrer() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const utmSource = urlParams.get("utm_source");

        if (utmSource) {
            let utmCookie = Cookies.get("utm_source");

            if (!utmCookie) {
                Cookies.set("utm_source", utmSource, { expires: 14 });
            }
        }

        const documentReferrer = document.referrer;

        if (documentReferrer) {
            Cookies.set("document_referrer", documentReferrer, { expires: 14 });
        }
    }

    loadVideo() {
        let videoWrapper = document.getElementById("home-video-wrapper");
        let videoWrapperHeight = videoWrapper.offsetHeight;

        videoWrapper.setAttribute("style", `height: ${videoWrapperHeight}px`);
        let youTubeEmbedCode = `<iframe src="https://www.youtube.com/embed/6FkaG83sAqE?enablejsapi=1&autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

        videoWrapper.innerHTML = youTubeEmbedCode;
    }

    setStickyHeader() {
        if (window.pageYOffset > 720) {
            this.stickyHeaderTarget.classList.add("header--visible");
        } else {
            this.stickyHeaderTarget.classList.remove("header--visible");
        }
    }

    resendVerificationEmail() {
        const verificationEmail = this.verificationEmailTarget.innerHTML;
        const resendVerificationEmailRoute = this.verificationEmailTarget.getAttribute("data-resend-verification-route");

        const formData = new FormData();
        formData.append("email", verificationEmail);

        this.resendVerificationEmailButtonTarget.disabled = true;

        fetch(resendVerificationEmailRoute, {
            method: "POST",
            body: formData,
            headers: {
                "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content")
            }
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            this.resendVerificationEmailButtonTarget.disabled = false;

            if (data.success) {
                successToast("The verification email has been resent. Please check your inbox.")
            } else {
                throw new Error(data.message);
            }
        })
        .catch((error) => {
            errorToast(error.message);
        });
    }

    toggleMobileMenu() {
        if (this.mobileMenuValue == "closed") {
            disableBodyScroll(this.mobileMenuTarget);
            this.mobileMenuBackgroundTarget.classList.add("background--visible");

            this.mobileMenuTarget.style.display = "flex";

            this.mobileMenuLinkTargets.forEach((element, index) => {
                setTimeout(() => {
                    element.classList.add("link--visible");
                }, 300 + index * 30);
            });

            this.mobileMenuValue = "open";
        } else {
            clearAllBodyScrollLocks();

            this.mobileMenuLinkTargets.forEach((element, index) => {
                setTimeout(() => {
                    element.classList.remove("link--visible");
                }, index * 30);
            });

            setTimeout(() => {
                this.mobileMenuBackgroundTarget.classList.remove("background--visible");
            }, 200);

            setTimeout(() => {
                this.mobileMenuTarget.style.display = "none";
            }, 800);

            this.mobileMenuValue = "closed";
        }
    }

}
