import Toastify from "toastify-js"

function showToast(text, type) {
    let duration = (text.length * 100) > 4000 ? (text.length * 100) : 4000;

    Toastify({
        text: text,
        className: `toastify--${type}`,
        gravity: "bottom",
        position: "center",
        duration: duration,
        stopOnFocus: true,
        offset: {
            y: 20
        }
    }).showToast();
}

export function successToast(text) {
    showToast(text, "success");
}

export function errorToast(text) {
    showToast(text, "error");
}